/* 进项发票- 增值税发票 */
export const INCOME_INVOICE_POOL_VAT = [
  {
    type: 'select',
    prop: 'invoiceType',
    name: 'invoiceType',
    label: '发票类型',
    placeholder: '请选择发票类型',
    options: [
      {
        label: '全部',
        value: ''
      },
      ...JSON.parse(localStorage.getItem('enums')).InvoiceType
    ]
  },
  {
    type: 'daterange',
    prop: 'invoiceTimeRange',
    name: 'invoiceTimeRange',
    label: '开票日期',
    format: 'yyyy-MM-dd HH:mm:ss'
  },
  {
    type: 'text',
    prop: 'sellerName',
    name: 'sellerName',
    label: '销售方',
    placeholder: '请输入销售方名称'
  },
  {
    type: 'daterange',
    prop: 'deductionDateRange',
    name: 'deductionDateRange',
    label: '抵扣日期',
    format: 'yyyy-MM-dd HH:mm:ss'
  },
  {
    type: 'select',
    prop: 'deductionStatus',
    name: 'deductionStatus',
    label: '抵扣状态',
    placeholder: '请选择抵扣状态',
    options: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '未抵扣',
        value: '0'
      },
      {
        label: '抵扣',
        value: '1'
      },
      {
        label: '退税',
        value: '2'
      },
      {
        label: '不抵扣',
        value: '4'
      }
    ]
  },
  {
    type: 'month',
    prop: 'signPeriod',
    name: 'signPeriod',
    label: '所属征期',
    placeholder: '请选择所属税期',
    format: 'yyyy-MM'
  },
  {
    type: 'text',
    name: 'invoiceCode',
    prop: 'invoiceCode',
    label: '发票代码',
    placeholder: '请输入发票代码'
  },
  {
    type: 'text',
    name: 'invoiceNo',
    prop: 'invoiceNo',
    label: '发票号码',
    placeholder: '请输入发票号码'
  },
  {
    type: 'select',
    name: 'ifRelateVoucher',
    prop: 'ifRelateVoucher',
    label: '是否生成凭证',
    placeholder: '请选择',
    options: [
      {
        label: '是',
        value: true
      },
      {
        label: '否',
        value: false
      }
    ]
  },
  {
    type: 'select',
    name: 'ifRelateVouProject',
    prop: 'ifRelateVouProject',
    label: '是否关联项目',
    placeholder: '请选择',
    options: [
      {
        label: '是',
        value: true
      },
      {
        label: '否',
        value: false
      }
    ]
  },
  {
    type: 'text',
    name: 'eInvoiceNo',
    prop: 'eInvoiceNo',
    label: '数电发票号码',
    placeholder: '请输入数电发票号码'
  }
];
/* 进项发票- 其他发票 */
export const INCOME_INVOICE_POOL_OTHER = [
  {
    type: 'select',
    prop: 'invoiceType',
    name: 'invoiceType',
    label: '发票类型',
    placeholder: '请选择发票类型',
    options: [
      {
        label: '全部',
        value: ''
      },
      ...JSON.parse(localStorage.getItem('enums')).InvoiceType
    ]
  },
  {
    type: 'daterange',
    prop: 'invoiceTimeRange',
    name: 'invoiceTimeRange',
    label: '开票日期',
    format: 'yyyy-MM-dd HH:mm:ss'
  },
  {
    type: 'text',
    name: 'invoiceCode',
    prop: 'invoiceCode',
    label: '发票代码',
    placeholder: '请输入发票代码'
  },
  {
    type: 'text',
    name: 'invoiceNo',
    prop: 'invoiceNo',
    label: '发票号码',
    placeholder: '请输入发票号码'
  },
  {
    type: 'text',
    name: 'rider',
    prop: 'rider',
    label: '乘车人',
    placeholder: '请输入乘车人'
  },
  {
    type: 'text',
    name: 'train21No',
    prop: 'train21No',
    label: ' 电子客票号码/21位码',
    placeholder: '请输入电子客票号码/21位码'
  },
  {
    type: 'select',
    name: 'ifRelateVoucher',
    prop: 'ifRelateVoucher',
    label: '是否生成凭证',
    placeholder: '请选择',
    options: [
      {
        label: '是',
        value: true
      },
      {
        label: '否',
        value: false
      }
    ]
  },
  {
    type: 'select',
    name: 'ifRelateVouProject',
    prop: 'ifRelateVouProject',
    label: '是否关联项目',
    placeholder: '请选择',
    options: [
      {
        label: '是',
        value: true
      },
      {
        label: '否',
        value: false
      }
    ]
  },
  {
    type: 'text',
    name: 'eInvoiceNo',
    prop: 'eInvoiceNo',
    label: '数电发票号码',
    placeholder: '请输入数电发票号码'
  }
];
/* 进项发票 */
export const SALES_INVOICE_POOL = [
  {
    type: 'select',
    prop: 'invoiceType',
    name: 'invoiceType',
    label: '发票类型',
    placeholder: '请选择发票类型',
    options: [
      {
        label: '全部',
        value: ''
      },
      ...JSON.parse(localStorage.getItem('enums')).InvoiceType
    ]
  },
  {
    type: 'daterange',
    prop: 'invoiceTimeRange',
    name: 'invoiceTimeRange',
    label: '开票日期',
    format: 'yyyy-MM-dd HH:mm:ss'
  },
  {
    type: 'select',
    prop: 'invoiceStatus',
    name: 'invoiceStatus',
    label: '发票状态',
    placeholder: '请选择发票状态',
    options: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '蓝票',
        value: '0'
      },
      {
        label: '红票',
        value: '1'
      },
      {
        label: '红冲',
        value: '2'
      },
      {
        label: '作废',
        value: '3'
      }
    ]
  },
  {
    type: 'text',
    prop: 'buyerName',
    name: 'buyerName',
    label: '购买方',
    placeholder: '请输入购买方'
  },
  {
    type: 'text',
    name: 'invoiceCode',
    prop: 'invoiceCode',
    label: '发票代码',
    placeholder: '请输入发票代码'
  },
  {
    type: 'text',
    name: 'invoiceNo',
    prop: 'invoiceNo',
    label: '发票号码',
    placeholder: '请输入发票号码'
  },
  {
    type: 'select',
    prop: 'equipmentExtensionNo',
    name: 'equipmentExtensionNo',
    label: '税控设备',
    placeholder: '请选择税控设备',
    options: []
  },
  {
    type: 'text',
    name: 'payer',
    prop: 'payer',
    label: '开票人',
    placeholder: '请输入开票人'
  },
  {
    type: 'select',
    prop: 'source',
    name: 'source',
    label: '发票来源',
    placeholder: '请选择发票来源',
    options: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '收票中心',
        value: 'CENTER'
      },
      {
        label: '结算发票',
        value: 'SETTLEMENT'
      },
      {
        label: '同步推送',
        value: 'PUSH'
      },
      {
        label: '第三方查验',
        value: 'THIRD'
      },
      {
        label: '微信小程序',
        value: 'WECHAT'
      },
      {
        label: '系统开具',
        value: 'ISSUE'
      },
      {
        label: '邮箱收票',
        value: 'EMAIL'
      }
    ]
  },
  {
    type: 'select',
    name: 'ifRelateVoucher',
    prop: 'ifRelateVoucher',
    label: '是否生成凭证',
    placeholder: '请选择',
    options: [
      {
        label: '是',
        value: true
      },
      {
        label: '否',
        value: false
      }
    ]
  },
  {
    type: 'select',
    name: 'ifRelateVouProject',
    prop: 'ifRelateVouProject',
    label: '是否关联项目',
    placeholder: '请选择',
    options: [
      {
        label: '是',
        value: true
      },
      {
        label: '否',
        value: false
      }
    ]
  },
  {
    type: 'text',
    name: 'eInvoiceNo',
    prop: 'eInvoiceNo',
    label: '数电发票号码',
    placeholder: '请输入数电发票号码'
  }
];
